import '../css/app.css';
import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';

if (document.getElementById('map')) {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    let mymap = L.map('map', {
        boxZoom: false,
        doubleClickZoom: false,
        dragging: false,
        keyboard: false,
        scrollWheelZoom: false,
        tap: false,
        touchZoom: false,
        zoomControl: false
    }).setView([46,2], 5);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoianZhc3BhcmQiLCJhIjoiY2tuNHE1ZnV5MDliMDJ2b2ZqNG1qYjIxciJ9.aoGbBw6H1mv5fkI2ot3R0A'
    }).addTo(mymap);

    L.marker([45.7591577,3.1306935]).addTo(mymap); // Clermont
    L.marker([47.3109361,5.079096]).addTo(mymap); // Dijon
    L.marker([48.5591997,7.7414133]).addTo(mymap); // Strasbourg

    L.marker([44.6066832,1.9880748]).addTo(mymap); // Figeac
    L.marker([48.8588536,2.3120407]).addTo(mymap); // Paris
    L.marker([48.6880796,6.1559274]).addTo(mymap); // Nancy
    L.marker([45.4347220,4.3902780]).addTo(mymap); // St Etienne
}

/* Dark mode */

window.onload = () => {
    document.getElementById('js-dark-switcher-zone').classList.remove('hidden');

    document.getElementById('js-dark-switcher').addEventListener('click', () => {
        let wanted = document.documentElement.classList.contains('dark') ? 'light' : 'dark';
        let preference = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

        if (wanted === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark');
        }

        if (wanted === preference) {
            localStorage.removeItem('theme');
        } else {
            localStorage.setItem('theme', wanted);
        }
    })
};